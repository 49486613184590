
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BombInfoModal from '../modals/bombInfoModal.vue';

@Options({
    components: {}
})
export default class BombTriggeredNotBlocked extends Vue {

    @Prop() personName: string;
    @Prop() chatRoomIdentifier: string;

    created() {}

    bombInfo() {
        this.$opModal.show(BombInfoModal, {});
    }
}
