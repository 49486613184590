
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ReportQuestion from '@/components/reportQuestion.vue';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import * as VM from '@/viewModel';
import * as OM from '@/Model';

@Options({
    components: {
        ReportQuestion,
        LoveFaceShadowBar
    }
})
export default class Answer extends Vue {

    @Prop() matchIdentifier: string;
    @Prop() userIdentifier: string;

    @Prop({
        default: () => new OM.NewMatchQuestion()
    }) answer: OM.NewMatchQuestion;

    @Prop({
        default: () => new OM.Answer()
    }) answers: OM.Answer;

    @Prop({
        default: ""
    }) question: string;

    @Prop() questionsCount: number;
    @Prop() questionIndex: number;

    @Prop({
        default: false
    }) blockRightArrow: boolean;

    @Prop() showNotBlurredImage: boolean;

    created() {}

}
