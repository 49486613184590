
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ReportQuestion from '@/components/reportQuestion.vue';
import RispostaEliminataPremiumModal from '../modals/rispostaEliminataPremiumModal.vue';
import PremiumAttentionBombModal from '../modals/premiumAttentionBombModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ReportQuestion
    }
})
export default class Question extends Vue {

    @Prop({
        default: () => new OM.NewMatchQuestion()
    }) currentQuestion: OM.NewMatchQuestion;

    @Prop({
        default: false
    }) hasPremium: boolean;

    @Prop() questionIndex: number;

    @Prop() matchIdentifier: string;
    @Prop() userIdentifier: string;

    @Prop({
        default: ""
    }) personName: string;

    created() {
        // && this.hasPremium
        if(this.currentQuestion.minPercentage != null) {
            this.$opModal.show(PremiumAttentionBombModal, {
                personName: this.personName
            })
        }
    }

    reply(answerIndex: number, answerText: string) {
        if(this.checkDisable(answerIndex)) {
            this.$opModal.show(RispostaEliminataPremiumModal, {});
        }
        else 
            this.$emit('replied', answerIndex, answerText);
    }

    checkDisable(index: number) {
        if(!this.currentQuestion.disabledAnswersIndex)
            return false;
            
        return this.currentQuestion.disabledAnswersIndex.find(x => x == index) != null;
    }

}
