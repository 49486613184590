
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BombInfoModal from './bombInfoModal.vue';

@Options({
    components: {
    }
})
export default class PremiumAttentionBombModal extends Vue {

    @Prop() personName: string;

    created() {
    }

    bombInfo() {
        this.$opModal.closeLast();
        this.$opModal.show(BombInfoModal, {});
    }

}
